
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { OrderTypeDetail, OrderEditDetail } from '@/types/newOrder'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'
import { ProjectUser } from '@/types/project'

@Component({
  components: { UploadFile }
})

export default class Add extends Vue {
  private typeList: Array<OrderTypeDetail> = []
  private projectUserList: Array<ProjectUser> = []
  private info: OrderEditDetail = {
    projectId: '',
    orderSubject: '',
    taskDetails: '',
    orderType: '',
    date: [],
    startDate: '',
    endDate: '',
    workArea: '',
    currentProcessor: '',
    fileList: []
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    orderSubject: [
      { required: true, message: '请输入工单主题', trigger: ['blur', 'change'] }
    ],
    taskDetails: [
      { required: true, message: '请输入工单详情', trigger: ['blur', 'change'] }
    ],
    orderType: [
      { required: true, message: '请选择工单类型', trigger: 'change' }
    ],
    date: [
      { required: true, message: '请选择作业日期', trigger: 'change' }
    ],
    workArea: [
      { required: true, message: '请输入作业区域', trigger: ['blur', 'change'] }
    ],
    currentProcessor: [
      { required: true, message: '请选择处理人', trigger: ['blur', 'change'] }
    ]
  }

  private isSubmit = false
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getTypeList()
  }

  getTypeList () {
    this.$axios.get<{ list: Array<OrderTypeDetail> }>(this.$apis.newOrder.selectFlowOrderTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  // 获取项目下所有人信息
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectProjectUserByList, {
      projectId: this.info.projectId
    }).then((res: { list: ProjectUser[]; total: number }) => {
      this.projectUserList = res.list || []
    })
  }

  projectChange (value: string) {
    this.info.currentProcessor = ''
    value && this.getProjectUserList()
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.isSubmit = true
        this.info.startDate = this.info.date[0]
        this.info.endDate = this.info.date[1]
        const info = JSON.parse(JSON.stringify(this.info))
        delete info.date
        this.$axios.post(this.$apis.newOrder.insertFlowOrder, info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'order' })
        }).finally(() => {
          this.isSubmit = false
        })
      }
    })
  }
}
